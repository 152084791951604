import React from 'react'
// import { graphql } from "gatsby"
import Header from '../components/header'
import SEO from '../components/SEO'
import Footer from '../components/footer'
import { display, lang } from '../utils'
import '../styles/blog.less'
import { navigate } from 'gatsby'
import { BlogCategory, BlogCategoryM } from '../components/block'
// import { lang } from "../utils"
const loadText = require('src/utils').loadText
const blogData = loadText('blog')
import { path } from 'ramda'

export const handleClick = (key: string, url: string, title: string) => {
  let target = ''
  const w = 500
  const h = 500
  const left = screen.width / 2 - w / 2
  const top = screen.height / 2 - h / 2
  switch (key) {
    case 'linkedin':
      target = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`
      break
    case 'twitter':
      target = `https://twitter.com/share?url=${url}$&via=TWITTER_HANDLE&text=${title}`
      break
    case 'facebook':
      target = `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`
      break
    default:
      target = `mailto:?subject=${title}&body=${decodeURI(url)}`
  }

  window.open(
    target,
    title,
    `width=${w}, height=${h}, top=${top}, left=${left}`
  )
}

export default function ({ pageContext }) {
  const {
    title,
    content,
    author,
    date,
    tags,
    excerpt,
    categories,
    allCategories,
    thumbnail = {},
    type,
  } = pageContext
  // console.log(type)
  // console.log(allCategories)
  // console.log(categories)
  // console.log(thumbnail)

  let _tags = (categories || [])
    .map((c) => ({ ...c, isC: true }))
    .concat(tags || [])
  // console.log(_tags)
  return (
    <div className="blog-article-wrapper">
      <SEO
        title={`${title} | Zenlayer`}
        featuredImage={path(['localFile', 'publicURL'], thumbnail)}
        description={(excerpt || '').replace(/<\/?[^>]+(>|$)/g, '')}
        keywords={_tags.map(({ name }) => name)}
      />
      <div className="headerContainer">
        <Header logo="/logo-blue.svg" />
      </div>
      <div className="only-mobile">
        <BlogCategoryM categories={allCategories} active="" type={type} />
      </div>
      <div className="blog-article-container full-bg">
        <div className="blog-article mobile-padding">
          <div className="blog-article-header">
            <h1
              className="font-36 semi-bold t-color"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className="font-20 mt-24">
              {author && (
                <>
                  {blogData.subject.by}{' '}
                  <span
                    className="blog-article-author"
                    onClick={() => {
                      navigate(
                        `/${
                          lang === 'zh' && type === 'blog' ? '' : `${type}/`
                        }author/${author.slug}/`
                      )
                    }}
                  >
                    {author.name}
                  </span>{' '}
                  {` | `}
                </>
              )}
              {display(date)}
            </div>
          </div>

          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="font-16 mt-32 blog-article-content"
          ></div>
          {_tags && (
            <div className="blog-article-tag mt-32">
              {_tags.map(({ name, slug, isC }) => (
                <div
                  className="blog-article-tagItem"
                  onClick={() => {
                    navigate(
                      `/${lang === 'zh' && type === 'blog' ? '' : `${type}/`}${
                        isC ? 'category' : 'tag'
                      }/${slug}/`
                    )
                  }}
                >
                  {name}
                </div>
              ))}
            </div>
          )}
          <div className="mt-24 blog-article-share">
            {(lang === 'zh'
              ? ['linkedin', 'email']
              : ['linkedin', 'twitter', 'facebook', 'email']
            ).map((key) => (
              <img
                src={`/blog/${key}-h.svg`}
                alt={`${key}_share`}
                onClick={() => {
                  handleClick(key, encodeURI(window.location.href), title)
                }}
              />
            ))}
          </div>
        </div>
        {allCategories.length > 0 && (
          <div className="only-desktop">
            <BlogCategory categories={allCategories} active="" type={type} />
          </div>
        )}
      </div>

      <Footer />
    </div>
  )
}
// export const query = graphql`
//   {
//     slug
//   }
// `
